@import url("https://fonts.googleapis.com/css2?family=Muli&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}

.navbar {
  background-color: #fff !important;
}
/* .navbar-brand {
  font-size: 1.5rem !important;
  color: #565387 !important;
} */

/* .navbar a {
  font-size: 1.3rem;
  text-transform: capitalize;
} */

/* .menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

.navbar a:hover {
  color: #3498db !important;
}

.uperrow {
  margin-left: auto;
  margin-bottom: 2px;
} */

.navbar {
  position: fixed--;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 1;
}

.nav-header {
  display: flex;
  justify-content: space-between;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}

.nav-links {
  height: 0;
  overflow: auto;
  transition: var(--mainTransition);
  list-style-type: none;
}

.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}

.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: 100px;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* @media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }
  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
} */

/* Main page styling */

#header {
  width: 100%;
  height: 80vh;
  margin-top: 50px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 600;
  color: black;
}
#header .brand-name {
  color: #3498db;
}

#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

.header-img {
  text-align: right;
}

.contact_div {
  width: 100%;
  height: 75vh;
}

footer p {
  padding: 0;
  margin: 0;
}

@media (max-width: 991px) {
  #header {
    height: 80vh;
    text-align: center;
  }

  #header .header-img {
    text-align: center;
  }

  #header .header-img img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #header {
    margin-top: 20px;
  }

  #header h1 {
    font-size: 28px;
  }

  #header h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  #header .header-img img {
    width: 70%;
  }
}
